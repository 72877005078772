
import * as strapiRuntime$cuhv7rxoC3 from '/var/www/planete-croisiere/prod/atrium/releases/114/node_modules/@nuxt/image/dist/runtime/providers/strapi'
import * as ipxRuntime$d6Lr4Kfbaq from '/var/www/planete-croisiere/prod/atrium/releases/114/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "preprod.catalogue.planete-croisiere.com",
    "catalogue.planete-croisiere.com",
    "localhost.atrium-bff.planete-croisiere.com",
    "preprod.atrium-bff.planete-croisiere.com",
    "bff.planete-croisiere.com",
    "blog.planete-croisiere.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$cuhv7rxoC3, defaults: {"baseURL":"https://bff.planete-croisiere.com"} },
  ['ipx']: { provider: ipxRuntime$d6Lr4Kfbaq, defaults: {} }
}
        